import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import { renderPageSections } from '../renderPageSections';
import SmbSuiteBannerSection from '../../organisms/SmbSuiteBannerSection/SmbSuiteBannerSection';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import useLocalize from '../../../hooks/useLocalize';

const SmbProductPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanitySmbProductPage === null) {
    return null;
  }

  const data = useLocalize(rawData, locale);

  const smbProductPageProps = {
    id: data.sanitySmbProductPage._id,
    metadata: { ...data.sanitySmbProductPage._rawRoute, href },
    suiteType: data.sanitySmbProductPage.suiteType,
    smbSuiteBannerSection: {
      ...data.sanitySmbProductPage._rawSmbSuiteBannerSection,
      suiteType: data.sanitySmbProductPage.suiteType,
    },
    sections: data.sanitySmbProductPage._rawSections,
    bubbleCtaSection: data.sanitySmbProductPage._rawBubbleCtaSection,
  };

  return (
    <Layout
      menuColor="gray"
      sanityId={smbProductPageProps.id}
      {...smbProductPageProps.metadata}
      bubbleCtaData={smbProductPageProps.bubbleCtaSection}
      locale={locale}
    >
      <SmbSuiteBannerSection {...smbProductPageProps.smbSuiteBannerSection} />
      {renderPageSections(smbProductPageProps.sections, {
        suiteType: smbProductPageProps.suiteType,
      })}
      {smbProductPageProps.bubbleCtaSection && (
        <BubbleCtaSection {...smbProductPageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

SmbProductPage.propTypes = {
  data: PropTypes.shape({
    sanitySmbProductPage: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      _rawRoute: PropTypes.shape({}),
      _rawSmbSuiteBannerSection: PropTypes.shape({}),
      _rawSections: PropTypes.arrayOf(PropTypes.shape({})),
      _rawBubbleCtaSection: PropTypes.shape({}),
      suiteType: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }),
};

SmbProductPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: '' },
};

export default SmbProductPage;

export const query = graphql`
  query($slug: String) {
    sanitySmbProductPage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawSmbSuiteBannerSection(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
      suiteType
    }
  }
`;
