/* eslint no-param-reassign: ["error", { "props": false }] */
import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import getOr from 'lodash/fp/getOr';
import ProductScreenAnimation from '../../molecules/ProductScreenAnimation/ProductScreenAnimation';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';
import PageWrapper from '../PageWrapper/PageWrapper';
import TextBlock from '../../molecules/TextBlock/TextBlock';
import { Heading1, Heading2 } from '../../atoms/Headings/Headings';
import Image from '../../atoms/Image/Image';
import sanityImage from '../../../utils/sanityImage';

const SmbSuiteBannerSection = ({
  backgroundImage,
  imageOrProductScreen,
  preTitle,
  suiteType,
  textBlock,
}) => {
  const backgroundUrl = backgroundImage
    ? sanityImage(backgroundImage)
        .auto('format')
        .fit('max')
        .url()
    : '';
  const suiteSectionCss = css`
    display: flex;
    min-height: ${uc('1022px')};
    background: url('${backgroundUrl}');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: ${pageDimensions.largeDesktopWidth};

    @media (${breakpoints.desktop}) {
      padding-right: ${uc('20px')};
      padding-left: ${uc('20px')};
    }
  `;

  const pageWrapperCss = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: ${uc('1022px')};

    @media (${breakpoints.tablet}) {
      flex-wrap: wrap;
      min-height: ${uc('750px')};
      margin: ${uc('130px auto 20px')};
    }
  `;

  const avatarTextCss = css`
    display: flex;

    @media (${breakpoints.mobile}) {
      flex-wrap: wrap;
    }
  `;

  const productScreenAnimationCss = css`
    margin-top: ${uc('-60px')};

    @media (${breakpoints.tablet}) {
      margin-top: ${uc('20px')};
    }
  `;

  const avatarCss = css`
    align-self: start;
    width: ${uc('105px')};
    margin-right: ${uc('30px')};

    @media (${breakpoints.mobile}) {
      margin: ${uc('30px auto')};
    }
  `;

  // style h2 to look like h1 for SEO optimization
  // style h1 to look like p for SEO optimization
  const textBlockCss = css`
    display: flex;
    flex-direction: column;
    max-width: ${uc('436px')};

    ${Heading2} {
      color: ${colors.darkGray.two};
      font-size: ${fontSizes.thirtyEight};
      line-height: ${lineHeights.copy};

      @media (${breakpoints.tablet}) {
        font-size: ${fontSizes.thirtyTwo};
      }

      @media (${breakpoints.mobile}) {
        font-size: ${fontSizes.twentyEight};
      }
    }

    ${Heading1} {
      max-width: ${uc('340px')};
      color: ${colors.darkGray.two};
      font-weight: ${fontWeights.normal};
      font-size: ${fontSizes.sixteen};
      line-height: ${lineHeights.copy};
      letter-spacing: 0;
    }

    .pre-title {
      color: ${colors.mediumGray};
    }

    a,
    button {
      min-width: ${uc('122px')};
      height: auto;
      text-align: left;

      @media (${breakpoints.smallDesktopMax}) {
        display: flex;
        align-items: center;
        text-align: center;
      }

      @media (${breakpoints.tablet}) {
        text-align: unset;
      }
    }

    @media (${breakpoints.largeTablet}) {
      .buttons {
        display: block;

        > * {
          display: block;
          width: 100%;
          margin-right: 0;
        }
      }
    }
  `;

  const textBlockButtons = getOr([], 'button', textBlock);
  textBlockButtons.forEach(button => {
    if (button._type === 'videoButton') {
      button.buttonOutline = true;
    }
  });

  return (
    <section css={suiteSectionCss}>
      <PageWrapper css={pageWrapperCss}>
        <div css={avatarTextCss}>
          <Image
            css={avatarCss}
            src={`/suiteAvatars/${suiteType}Avatar.svg`}
            alt="product suite icon"
          />
          <TextBlock
            css={textBlockCss}
            {...textBlock}
            HeadingType={Heading2}
            DescriptionType={Heading1}
            preTitle={preTitle}
          />
        </div>
        {imageOrProductScreen &&
          imageOrProductScreen.length > 0 &&
          imageOrProductScreen[0]._type === 'productScreenAnimation' && (
            <ProductScreenAnimation
              css={productScreenAnimationCss}
              {...imageOrProductScreen[0]}
            />
          )}
        {imageOrProductScreen &&
          imageOrProductScreen.length > 0 &&
          imageOrProductScreen[0]._type === 'csodImage' && (
            <Image
              css={productScreenAnimationCss}
              src={sanityImage(imageOrProductScreen[0])
                .auto('format')
                .width(1800)
                .fit('max')
                .url()}
              alt={imageOrProductScreen[0].alt}
            />
          )}
      </PageWrapper>
    </section>
  );
};

SmbSuiteBannerSection.propTypes = {
  backgroundImage: PropTypes.shape({}),
  imageOrProductScreen: PropTypes.arrayOf(PropTypes.shape([])),
  preTitle: PropTypes.string,
  suiteType: PropTypes.string,
  textBlock: PropTypes.shape({}),
};

SmbSuiteBannerSection.defaultProps = {
  backgroundImage: null,
  imageOrProductScreen: {},
  preTitle: '',
  suiteType: 'recruiting',
  textBlock: {},
};

export default SmbSuiteBannerSection;
